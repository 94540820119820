import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentLoaderModule } from '@ngneat/content-loader';

@Component({
  selector: 'mspot-content-loader',
  templateUrl: './content-loader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ContentLoaderModule],
  host: {
    class: 'block',
  },
})
export class ContentLoaderComponent {
  @Input() viewBox = '0 0 400 130';
}
